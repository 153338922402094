@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  height: 100%;
}

details > summary {
  list-style: none;
}
details > summary::-webkit-details-marker {
  display: none;
}
